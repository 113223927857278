.project-item .image {
  padding-top: 75%; /* controls image aspect ratio */
  position: relative;
}

.project-item .image * {
  object-fit: cover;
  height: 100%;
  position: absolute;
  top: 0;
}

.project-item .description-title {
  margin-bottom: 0rem;
}

.project-item .description-subtitle {
  margin-bottom: 1.5rem;
}

.project-item .description-body {
  margin-bottom: 2rem;
}

.project-item .buttons-container > * {
  margin-right: 1rem;
}

/* Desktop */
@media (min-width: 901px) {
  .project-item {
    margin: auto;
    width: var(--full-content-width);
    display: flex;
    align-items: center;
    margin-bottom: 7rem;

    --description-margin: 20%;
  }

  .project-item .description-column {
    flex: 1;
  }

  .project-item .image-column {
    flex: 1.25;
  }

  .project-item.image-left {
    flex-direction: row-reverse;
  }

  .project-item.image-right .description-column {
    margin-left: var(--safe-content-margin);
  }

  .project-item.image-right .description {
    margin-right: var(--description-margin);
  }

  .project-item.image-left .description-column {
    margin-right: var(--safe-content-margin);
  }

  .project-item.image-left .description {
    margin-left: var(--description-margin);
  }
}

/* Mobile */
@media (max-width: 900px) {
  .project-item {
    margin: auto;
    width: var(--safe-content-width);
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 6rem;
  }

  .project-item .image-column {
    margin-bottom: 1.5rem;
  }
}
