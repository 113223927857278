@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

:root {
  --desktop-max-width: 1480px;
  --full-content-width: min(var(--desktop-max-width), 95%);
  --safe-content-width: calc(var(--full-content-width) * 0.8);
  --safe-content-margin: calc(
    (var(--full-content-width) - var(--safe-content-width)) / 2
  );
  --bottom-page-margin: 4rem;

  --default-border-radius: 4px;
  --color-light-gray: #dedede;
  --color-medium-gray: #727272;
  --color-medium-gray-rgb: 114, 114, 114;
  --color-dark-gray: #333333;
  --color-medium-blue: #3484bd;
  --color-medium-blue-rgb: 52, 132, 189;
  --color-dark-blue: #235980;
}

.social-icons {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.625rem;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  color: #333;
}

img,
video {
  width: 100%;
  border-radius: var(--default-border-radius);
}

h1 {
  font-size: 4.5rem;
  font-weight: 600; /* Semibold */
  color: var(--color-medium-gray);
  margin: 0;
}

h2 {
  font-size: 2.75rem;
  font-weight: 600;
  color: var(--color-medium-gray);
  margin: 0;
}

h3 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-dark-gray);
  margin: 0;
}

h4 {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--color-dark-gray);
  margin: 0;
}

/* default link styling */
a:any-link {
  color: var(--color-medium-blue);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* selected link */
a:active {
  color: var(--color-dark-blue);
}

/* Mobile */
@media (max-width: 900px) {
  :root {
    --full-content-width: 90%;
    --safe-content-width: var(--full-content-width);
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.25rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.25rem;
  }
}
