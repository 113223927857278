.section-header {
  margin: auto;
  width: var(--full-content-width);
  margin-bottom: 3rem;
}

.section-header .line {
  width: auto;
  height: 0.5rem;
  background-color: var(--color-light-gray);
  border-radius: 2px;
  margin-left: calc(var(--safe-content-margin) * 0.5);
}

.section-header .title {
  margin-left: calc(var(--safe-content-margin) * 0.6);
  line-height: 1em;
}
