.site-header {
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 5.5rem;
  width: var(--safe-content-width);
}

.site-title {
  text-align: right;
}

.site-title a:any-link {
  text-decoration: inherit;
  color: inherit;
}

.navbar {
  text-align: right;
}

.navbar-link:any-link {
  color: var(--color-medium-gray);
  text-decoration: none;
  transition-property: color;
  transition-duration: 0.15s;
  margin-left: 2rem;
}

.navbar-link:hover {
  color: var(--color-medium-blue);
  transition-property: color;
  transition-duration: 0.15s;
}

/* Highlights the current page in the navbar */
.navbar-link.active {
  color: var(--color-medium-blue);
}

/* Mobile */
@media (max-width: 900px) {
  .site-header {
    margin-bottom: 4rem;
  }
}
