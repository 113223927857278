.about-section {
  margin: auto;
  width: var(--safe-content-width);
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 9rem;
}

.about-photo {
  padding-right: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-text p,
.about-text h3 {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Desktop-max-width */
@media (min-width: 1480px) {
  .about-photo {
    padding-left: 5%;
  }
}

/* Mobile: single-column */
@media (max-width: 900px) {
  .about-section {
    grid-template-columns: 1fr;
    margin-bottom: 7rem;
  }

  .about-photo {
    padding: 0 10% 4rem;
  }
}

.social-icons {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.625rem;
}

.social-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  width: 2rem;
  height: 2rem;
  margin-right: 1.125rem;
  margin-left: 0.25rem;
  transition-duration: 0.2s;
}

#linkedin-icon {
  background-image: url(../../assets/icons/linkedin-icon-grey.png);
}
#linkedin-icon:hover {
  background-image: url(../../assets/icons/linkedin-icon-color.png);
}

#github-icon {
  background-image: url(../../assets/icons/github-icon-grey.png);
}
#github-icon:hover {
  background-image: url(../../assets/icons/github-icon-color.png);
}

#instagram-icon {
  background-image: url(../../assets/icons/instagram-icon-grey.png);
}
#instagram-icon:hover {
  background-image: url(../../assets/icons/instagram-icon-color.png);
}

/* Image pre-loading trick -- prevents delay to show color version on hover */
body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url(../../assets/icons/linkedin-icon-color.png)
    url(../../assets/icons/github-icon-color.png)
    url(../../assets/icons/instagram-icon-color.png);
}
